<template>
  <div class="login-wrap">
    <el-form
        class="login-form"
        label-position="top"
        label-width="80px"
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
    >
      <h2>用户登录</h2>
      <el-form-item label="用户名" required prop="username">
        <el-input v-model="loginForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" required prop="password">
        <el-input v-model="loginForm.password" type="password"></el-input>
      </el-form-item>
      <el-form-item label="验证码" required prop="captchaCode">
        <el-input v-model="loginForm.captchaCode" type="text" style="width: 55%; float: left; margin-right: 1em"/>
        <img :src="loginForm.captchaCodeUrl" @click="refreshCode" alt="验证码"/>
      </el-form-item>
      <el-button class="submit-btn" type="primary" @click="submitForm('loginForm')">登录</el-button>
    </el-form>
  </div>
</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        captchaCodeUrl:"",
        captchaCode:"",
        userKey:""
      },
      rules: {
        username: [{required: true, message: "用户名不可为空", trigger: 'blur'}],
        password: [{required: true, message: "密码不可为空", trigger: 'blur'}],
        captchaCode: [{required: true, message: "验证码不可为空", trigger: 'blur'}]
      }
    }
  },
  created() {
    document.title = '登录页';
  },
  methods:{
    submitForm(formName) {
      console.log('提交登录信息',formName);
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          this.$message.error('校验未通过，提交失败!');
          return false;
        }

        this.$axios({
          method: 'post',
          url: '/api/login',
          data: {
            username: this.loginForm.username,
            password: this.loginForm.password
          },
          headers:{
            'Captcha-Code': this.loginForm.captchaCode,
            'User-Key': this.loginForm.userKey,
          }
        }).then(res => {

          localStorage.removeItem("token");
          console.log('Login res=',res);
          if (!res || !res.data) {
            this.$message.error("登录失败!");
            this.refreshCode();
            return;
          }

          if (res.data.code !== 100000) {
            this.$message.error("登录失败, " + res.data.message);
            this.refreshCode();
            return;
          }

          this.$refs[formName].resetFields();
          //this.$message.success('登录成功');
          console.log('token=', res.headers['authorization']);
          localStorage.setItem("token", res.headers['authorization']);
          this.refreshCode();
          this.$router.push({ name: 'FeedbackDetail' });
        }, err => {
          console.error('Login Error:', err);
          this.refreshCode();
          this.$message.error('登录失败，请稍后再试')
          localStorage.removeItem("token");
        });

      });
    },
    async refreshCode() {
      const res = await this.$axios.get("/api/captcha");
      console.log('获取验证码完成, res=', res);
      if (!res || !res.data || res.data.code !== 100000) {
        this.$message.error({
          message: "验证码加载失败"
        })
        return;
      }
      this.loginForm.captchaCodeUrl = res.data.data.base64Img;
      this.loginForm.userKey = res.data.data.key;
    }
  },
  mounted() {
    this.refreshCode();
  }
}
</script>

<style>
.login-wrap {
  height: 100vh;
  background: #324152;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  width: 80%;
  background: #fff;
  border-radius: 5px;
  padding: 30px;
}

.login-btn {
  width: 100%;
}
body {
  margin: -8px; /* 去除body的默认边距 */
  padding: 8px; /* 添加一个与body默认边距相同的内边距 */
}

</style>