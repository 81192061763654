<template>
  <div>
    <el-container>
      <el-main>
        <el-table
            v-loading="loading"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="tableData"
            stripe
            style="width: 100%">
          <el-table-column
              prop="currentDate"
              label="填写时间"
              width="180">
          </el-table-column>
          <el-table-column
              prop="questionType"
              label="问题类型"
              width="180">
          </el-table-column>
          <el-table-column
              prop="desc"
              label="问题描述">
          </el-table-column>
          <el-table-column
              prop="risk"
              label="潜在风险">
          </el-table-column>
          <el-table-column
              prop="suggestion"
              label="建议方法">
          </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="1000">
        </el-pagination>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {questionTypeOptions} from '@/assets/js/status'

export default {
  name: 'DetailPage',
  components: [questionTypeOptions],
  data() {
    return {
      tableData: [],
      loading: true
    }
  },
  created() {
    this.loadData();
    document.title = '反馈详情页';
  },
  filters: {
    questionTypeFilter(code) {
      let qt = questionTypeOptions.find(item => item.code === code);
      return qt === undefined ? '未知' : qt.desc
    },
  },
  methods: {
    loadData() {
      console.log('Detail page loadData')
      this.$axios({
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
        method: 'post',
        url: '/api/feedback/list'
      }).then(res => {
        this.loading = false;
        console.log(res)
        if (!res || !res.data) {
          this.$message.error('数据查询失败，请稍后重试')
          return;
        }
        if (res.data.code !== 100000) {
          this.$message.warning('无查看权限')
          this.$router.push({name: 'Login'});
          return;
        }
        this.tableData = res.data.data;
        this.handleQuestionType();
      }, err => {
        console.error('Detail Error:', err);
        this.loading = false;
        this.$message.error('数据获取失败，请稍后再试')
        this.$router.push({name: 'Login'});
      });
    },
    handleQuestionType() {
      this.tableData.forEach(item => {
        item.questionType = this.$options.filters.questionTypeFilter(item.questionType);
      })
    },
  }
}
</script>

<style scoped>
</style>
