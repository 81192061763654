<template>
  <div id="app">
<!--    <img src="./assets/logo.png">-->
<!--    <HomePage/>-->
    <router-view/>
  </div>
</template>

<script>
// import HomePage from './pages/feedback/Home.vue'

export default {
  components: {
    // HomePage
  },
  methods: {
    startHacking() {
      this.$axios({
        method: 'post',
        url: '/api/hello',
        data: {
          id: 10
        }
      }).then(res => {
        console.log(res)
      }, err => {
        console.error(err)
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Helvetica, sans-serif;
  //text-align: center;
}

</style>
