<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" style=" margin:0 auto; width: 85%">
      <h2>问题反馈表</h2>
      <el-form-item label="当前时间" prop="currentDate" required>
        <el-form-item prop="currentTime">
          <el-date-picker
              type="datetime"
              placeholder="选择日期和时间"
              v-model="ruleForm.currentDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%;"
              readonly disabled>
          </el-date-picker>
        </el-form-item>
      </el-form-item>

      <el-form-item label="问题类型" prop="questionType">
        <el-select v-model="ruleForm.questionType" placeholder="请选择问题类型" style="width: 100%">
          <el-option label="公司规则制度" value="1"></el-option>
          <el-option label="体系文件" value="2"></el-option>
          <el-option label="生产线" value="3"></el-option>
          <el-option label="合理化建议" value="4"></el-option>
          <el-option label="公司网站" value="5"></el-option>
          <el-option label="其他" value="0"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="问题描述" required prop="desc">
        <el-input type="textarea" :rows="4" v-model="ruleForm.desc"></el-input>
      </el-form-item>

      <el-form-item label="潜在风险" required prop="risk">
        <el-input type="textarea" :rows="4" v-model="ruleForm.risk"></el-input>
      </el-form-item>

      <el-form-item label="建议方法" required prop="suggestion">
        <el-input type="textarea" :rows="4" v-model="ruleForm.suggestion"></el-input>
      </el-form-item>

      <el-button class="submit-btn" type="primary" @click="submitForm('ruleForm')">提 交</el-button>
    </el-form>
  </div>
</template>

<script>
import {getNow} from "@/utils/date-util"

export default {
  name: 'HomePage',
  data() {
    return {
      ruleForm: {
        currentDate: null,
        questionType: null,
        desc: null,
        risk: null,
        suggestion: null,
      },
      rules: {
        currentDate: [
          {required: true, message: "当前时间不可为空", trigger: 'blur'}
        ],
        questionType: [
          {required: true, message: "请选择问题类型", trigger: 'blur'}
        ],
        desc: [
          {required: true, message: "请输入问题描述", trigger: 'blur'}
        ],
        risk: [
          {required: true, message: "请输入潜在风险", trigger: 'blur'}
        ],
        suggestion: [
          {required: true, message: "请输入建议方法", trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.$set(this.ruleForm, "currentDate", getNow());
    document.title = '反馈收集';
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('校验未通过，提交失败!!');
          return false;
        }

        this.$axios({
          method: 'post',
          url: '/api/feedback/create',
          data: {
            currentDate: this.ruleForm.currentDate,
            questionType: this.ruleForm.questionType,
            desc: this.ruleForm.desc,
            risk: this.ruleForm.risk,
            suggestion: this.ruleForm.suggestion
          }
        }).then(res => {
          console.log('提交完成, res=', res)
          if (!res || !res.data || res.data.code !== 100000) {
            this.$message.error('操作失败，请稍后再试');
            return;
          }

          this.$message.success('提交成功');
          this.$refs[formName].resetFields();
        }, err => {
          console.error(err)
          this.$message.error('提交失败，请稍后再试')
        });

      });
    },
  }

}
</script>

<style scoped>
.submit-btn {
  display: block;
  width: 100%;
  margin: 0 auto
}
</style>
