<template>
  <div class="login-wrap">
    <el-form
        class="edit-form"
        label-position="top"
        label-width="80px"
        :model="editForm"
        :rules="rules"
        ref="editForm"
    >
      <h2>修改密码</h2>
      <el-form-item label="用户名" required prop="username">
        <el-input v-model="editForm.username"></el-input>
      </el-form-item>
      <el-form-item label="旧密码" required prop="oldPwd">
        <el-input v-model="editForm.oldPwd" type="password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" required prop="newPwd">
        <el-input v-model="editForm.newPwd" type="password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" required prop="duplicatePwd">
        <el-input v-model="editForm.duplicatePwd" type="password"></el-input>
      </el-form-item>
      <el-form-item label="验证码" required prop="captchaCode">
        <el-input v-model="editForm.captchaCode" type="text" style="width: 55%; float: left; margin-right: 1em"/>
        <img :src="editForm.captchaCodeUrl" @click="refreshCode" alt="验证码"/>
      </el-form-item>
      <el-button class="submit-btn" type="primary" @click="submitForm('editForm')">提交</el-button>
    </el-form>
  </div>
</template>

<script>

export default {
  name: "ModifyPassword",
  data() {
    return {
      editForm: {
        username: "",
        oldPwd: "",
        newPwd: "",
        duplicatePwd: "",
        captchaCodeUrl: "",
        captchaCode: "",
        userKey: ""
      },
      rules: {
        username: [{required: true, message: "请输入用户名", trigger: 'blur'}],
        oldPwd: [{required: true, message: "请输入旧密码", trigger: 'blur'}],
        newPwd: [{required: true, message: "请输入新密码", trigger: 'blur'}],
        duplicatePwd: [{required: true, message: "请输入确认密码", trigger: 'blur'}],
        captchaCode: [{required: true, message: "请输入验证码", trigger: 'blur'}]
      }
    }
  },
  beforeCreate() {
    document.title = '用户信息管理';
  },
  created() {
    document.title = '修改密码';
  },
  methods: {
    submitForm(formName) {
      console.log('提交修改', formName);
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          this.$message.error('校验未通过，提交失败!');
          return false;
        }

        this.$axios({
          method: 'post',
          url: '/api/user/modify',
          data: {
            username: this.editForm.username,
            oldPwd: this.editForm.oldPwd,
            newPwd: this.editForm.newPwd,
            duplicatePwd: this.editForm.duplicatePwd
          },
          headers: {
            'Captcha-Code': this.editForm.captchaCode,
            'User-Key': this.editForm.userKey,
          }
        }).then(res => {
          console.log('Login res=', res);
          if (!res || !res.data) {
            this.$message.error("操作失败!");
            this.refreshCode();
            return;
          }

          if (res.data.code !== 100000) {
            this.$message.error("操作失败, " + res.data.message);
            this.refreshCode();
            return;
          }

          this.$refs[formName].resetFields();
          this.$message.success('修改成功, 跳转登录页!');
          this.refreshCode();
          this.$router.push({name: 'Login'});
        }, err => {
          console.error('Login Error:', err);
          this.refreshCode();
          this.$message.error('操作失败，请稍后再试')
        });

      });
    },
    async refreshCode() {
      const res = await this.$axios.get("/api/captcha");
      console.log('获取验证码完成, res=', res);
      if (!res || !res.data || res.data.code !== 100000) {
        this.$message.error({
          message: "验证码加载失败"
        })
        return;
      }
      this.editForm.captchaCodeUrl = res.data.data.base64Img;
      this.editForm.userKey = res.data.data.key;
    }
  },
  mounted() {
    this.refreshCode();
  }
}
</script>

<style>
.login-wrap {
  height: 100vh;
  background: #324152;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-form {
  width: 80%;
  background: #fff;
  border-radius: 5px;
  padding: 30px;
}

.login-btn {
  width: 100%;
}

body {
  margin: -8px; /* 去除body的默认边距 */
  padding: 8px; /* 添加一个与body默认边距相同的内边距 */
}

</style>