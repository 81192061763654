export function getNow() {
  const now = new Date()
  return new Date(+ now + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
}

export function getLastThreeMonth() {
  return getLastThreeMonthWithDefaultTime()
}

export function getLastThreeMonthWithRealTime() {
  const end = new Date()
  const start = new Date()
  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)

  const tmpstart = new Date(+start + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
  const tmpend = new Date(+end + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
  return [tmpstart, tmpend]
}



export function getLastThreeMonthWithDefaultTime() {
  const end = new Date()
  const start = new Date()
  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)

  const tmpstart = new Date(+start + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').split(' ')[0] + ' 00:00:00'
  const tmpend = new Date(+end + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').split(' ')[0] + ' 23:59:59'
  return [tmpstart, tmpend]
}

export function getCurrentYear() {
  return String(new Date().getFullYear());
}
