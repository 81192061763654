import VueRouter from "vue-router";
import Login from '@/pages/login/Login.vue';
import FeedbackHome from '@/pages/feedback/Home.vue';
import FeedbackDetail from '@/pages/feedback/Detail.vue';
import ModifyPassword from "@/pages/login/ModifyPassword.vue";

//创建并导出一个路由器
const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/login',
            name: "Login",
            component: Login
        },
        {
            path: '/home',
            component: FeedbackHome
        },
        {
            path: '/edit',
            name: 'ModifyPassword',
            component: ModifyPassword
        },
        {
            path: '/detail',
            name: 'FeedbackDetail',
            component: FeedbackDetail,
            meta: {
                requiresAuth: true
            }
        }
    ]
})

router.beforeEach((to, from, next) => {
    console.log("to", to)
    console.log("from", from)
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (requiresAuth && !localStorage.getItem("token")) {
        //next('/login');
        next();
    } else {
        next();
    }
});

export default router;